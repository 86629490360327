import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box, CheckBox, EditSelect, ICommand, RelayCommand } from "Application";
import { TSMListWrapper, TsmActionsCell } from "./TSMList.styles";
import { createColumnHelper } from "@tanstack/react-table";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { TSMListViewModel } from "./TSMListViewModel";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";
import { BooleanTickOrCrossCell, CenteredCell, CenteredTextCell, HyphenIfNoValueCell } from "Utils/CellFormatComponents";
import moment from "moment";
import { P } from "Components/Primitives/TextElements/TextElements";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePairExtended } from "Models/KeyValuePairExtended";
import { ProductTypeSelectWrapper } from "Components/Wrappers/ProjectTypeSelectWrapper";
import { KeyValuePair } from "@shoothill/core";
import { ProjectStatusBox, ProjectStatusWrapper } from "Views/Work/Common/ProjectsList.styles";
import { HiddenVisibleToggle } from "Components/HiddenVisibleToggle";
import { DocNoteCountIcon, DocOrNote } from "Components/DocCountIcon";
import { TSMNotesModalView } from "./Modals/TSMNotesModal/TSMNotesModalView";
import { formatCurrency } from "Utils/Formats";
import { TSMDocumentsModalView } from "./Modals/TSMDocumentsModal/TSMDocumentsModalView";
import { SiteVisitButton } from "Components/Primitives/Buttons/SiteVisitButton";
import { TsmSiteVisitModal } from "./Modals/TSMSiteVisitModal/TSMSiteVisitModal";
import { LoadingSpinner } from "Components/LoadingSpinner";
import { useNavigate } from "react-router";
import { AppUrlIndex, AppUrls } from "AppUrls";
import { container } from "tsyringe";
import { AccountStore } from "Stores/Domain";

export const TSMListView: React.FC = observer(() => {
    const [viewModel] = useState(() => TSMListViewModel.Instance);
    let debounceTimer: NodeJS.Timeout | null = null;
    const columnHelper = createColumnHelper<any>();
    let navigate = useNavigate();
    const accountStore = container.resolve(AccountStore);
    const isAdmin = () => accountStore.isInRole("ADMIN");

    const [isFiltering, setIsFiltering] = useState(false);
    useEffect(() => {
        if (accountStore.TSMId) {
            viewModel.setLoggedInTsm(accountStore.TSMId);
        }
        if (viewModel.tsmId > 0) {
            viewModel.loadTSM();
        }

        if (isAdmin() === true) {
            viewModel.loadTsmsForAdminDropdown();
        }

        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columns = [
        columnHelper.accessor("number", {
            size: 50,
            header: () => "Prj no.",
            cell: (info) => (
                <P>
                    <strong>{info.getValue()}</strong>
                </P>
            ),
        }),

        columnHelper.accessor("projectQuoteName", {
            size: 400,
            header: () => "Project quote name",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("product", {
            size: 80,
            header: () => "Product",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("contractors", {
            size: 300,
            header: () => "Contractor",
            cell: (info) => info.renderValue(),
        }),

        columnHelper.accessor("postcode", {
            size: 40,
            header: () => "Postcode",
            cell: (info) => <CenteredTextCell value={info.renderValue()} />,
        }),

        columnHelper.accessor("netValue", {
            size: 100,
            header: () => "Net value",
            cell: (info) => {
                return formatCurrency(info.renderValue());
            },
        }),

        columnHelper.accessor("overallStatus", {
            header: () => "Status",
            cell: (info) => {
                const status = viewModel.projectStatuses.find((a) => a.statusId === info.getValue());
                if (status !== undefined) {
                    return (
                        <ProjectStatusBox className={status.className}>
                            <div>{status.displayName}</div>
                        </ProjectStatusBox>
                    );
                } else {
                    return "";
                }
            },
        }),

        columnHelper.accessor("isSurveyedByTsm", {
            size: 80,
            header: () => "Surveyed",
            cell: (info) => <BooleanTickOrCrossCell value={info.renderValue()} />,
        }),

        columnHelper.accessor("siteInspection", {
            size: 80,
            header: () => "Site Inspection",
            cell: (info) =>
                viewModel.isVisitedProcessing && info.row.original.id === viewModel.inspectionSiteIdProcessing ? (
                    <LoadingSpinner heightOfSpinner="27.6px" />
                ) : (
                    <CenteredCell pl="10px" onClick={(e: any) => viewModel.toggleSiteInspection(e, info.row.original.id, info.renderValue())}>
                        <CheckBox value={() => info.renderValue()} command={viewModel.dummyCommand} />
                    </CenteredCell>
                ),
        }),

        columnHelper.accessor("createdDate", {
            size: 70,
            header: () => "Created date",
            cell: (info) => {
                return <CenteredTextCell value={moment.utc(info.getValue()).format("DD/MM/yyyy")} />;
            },
        }),

        columnHelper.accessor("despatchDate", {
            size: 70,
            header: () => "Despatch date",
            cell: (info) => {
                return info.getValue() === null ? <HyphenIfNoValueCell value={info.getValue()} /> : <CenteredTextCell value={moment.utc(info.getValue()).format("DD/MM/yyyy")} />;
            },
        }),

        columnHelper.accessor("docCount", {
            size: 75,
            header: () => "Docs",
            cell: (info) => (
                <DocNoteCountIcon count={info.renderValue()} projectId={info.row.original.id} docOrNote={DocOrNote.Document} onClickCommand={viewModel.openDocumentModal} />
            ),
        }),

        columnHelper.accessor("noteCount", {
            size: 75,
            header: () => "Notes",
            cell: (info) => <DocNoteCountIcon count={info.renderValue()} projectId={info.row.original.id} docOrNote={DocOrNote.Note} onClickCommand={viewModel.openNoteModal} />,
        }),

        columnHelper.accessor("showOnTsmTab", {
            header: () => "Actions",
            cell: (info) => (
                <TsmActionsCell>
                    <SiteVisitButton
                        id={info.row.original.id}
                        onClick={(e) => {
                            viewModel.openSiteVisitModal(e, info.row.original.id);
                        }}
                    />
                    <HiddenVisibleToggle
                        isVisible={info.renderValue()}
                        id={info.row.original.id}
                        toggleIsVisible={viewModel.toggleIsVisible}
                        isProcessing={viewModel.isVisibleProcessing}
                    />
                </TsmActionsCell>
            ),
            enableSorting: false,
        }),
    ];

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.updateSorting(columnName, orderBy);
    };

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadTSM();
            setIsFiltering(false);
        }, 1200);
    };

    const updateProductFiltering: ICommand = new RelayCommand(
        (values: KeyValuePairExtended[]) => {
            let children: string[] = viewModel.products.filter((item) => item.class === "child").map((child) => child.key);
            viewModel.updateProductFiltering(values.filter((item) => children.includes(item.key)).map((item) => item.key));
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const productSelectAll = (): void => {
        viewModel.productSelectAll();
        viewModel.loadTSM();
    };
    const productSelectNone = (): void => {
        viewModel.productSelectNone();
        viewModel.loadTSM();
    };

    const updateStatusFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateStatusFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const statusSelectAll = (): void => {
        viewModel.statusSelectAll();
        viewModel.loadTSM();
    };
    const statusSelectNone = (): void => {
        viewModel.statusSelectNone();
        viewModel.loadTSM();
    };

    const updateSurveyedFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateSurveyedFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const updateVisitedFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateVisitedFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const updateVisibleFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateVisibleFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            viewModel.pageNumber = 1;
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const updateContractorFiltering: ICommand = new RelayCommand(
        (values: KeyValuePair[]) => {
            viewModel.updateContractorFiltering(
                values.map((item) => {
                    return item.key;
                }),
            );
            debounceFilter();
        },
        () => {
            return viewModel.getCanExecute;
        },
    );

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadTSM();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadTSM();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadTSM();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadTSM();

                break;
            default:
            // code block
        }
    };

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchCommand={viewModel.updateKeywordSearch}
            timescaleOptions={[]}
            timescaleValue={""}
            visibleHiddenCommand={updateVisibleFiltering}
            visibleValue={viewModel.getVisibleFilter}
            visibleHiddenSelectAll={viewModel.visibleSelectAll}
            visibleHiddenSelectNone={viewModel.visibleSelectNone}
            isDisabled={!viewModel.getCanExecute}
            displayName="Start typing to search by project number, project name,product, contractor, postcode etc..."
        />
    );

    const formatOptionLabel = ({ value, label, cls }: any) => <p className={cls}>{label}</p>;

    function onRowClick(rowId: string | number): void {
        const path: string = AppUrls.Client.Work.Quotes.View[AppUrlIndex.Long].replace(":projectquoteid", rowId.toString()) + "/details";
        navigate(path);
    }

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <ProductTypeSelectWrapper>
                <MultiEditSelect
                    command={updateProductFiltering}
                    displayName="Product"
                    options={viewModel.getProducts}
                    value={() => viewModel.getProductFilter}
                    showSelectAllNone={true}
                    selectAll={productSelectAll}
                    selectNone={productSelectNone}
                    formatOptionLabel={formatOptionLabel}
                    isOptionDisabled={(option) => option.class == "parent"}
                    hasParentChildren={true}
                    isDisabled={viewModel.isProcessing}
                />
            </ProductTypeSelectWrapper>
            <MultiEditSelect
                command={updateContractorFiltering}
                displayName="Contractors"
                options={viewModel.getContractorsForDropdown}
                value={() => viewModel.getContractorFilter}
                onInputChange={(e) => {
                    viewModel.getContractorsBySearchString(e);
                }}
                showClearButton={true}
                clearDropdownList={viewModel.clearDropdownList}
                isDisabled={viewModel.isProcessing}
            />
            <ProjectStatusWrapper>
                <MultiEditSelect
                    command={updateStatusFiltering}
                    displayName="Filter by status"
                    options={viewModel.getStatuses}
                    value={() => viewModel.getStatusFilter}
                    showSelectAllNone={true}
                    selectAll={statusSelectAll}
                    selectNone={statusSelectNone}
                    isDisabled={viewModel.isProcessing}
                />
            </ProjectStatusWrapper>
            <MultiEditSelect
                command={updateSurveyedFiltering}
                displayName="Surveyed"
                options={viewModel.getBooleanOptionsForDropdown}
                value={() => viewModel.getSurveyedFilter}
                showSelectAllNone={true}
                selectAll={viewModel.surveyedSelectAll}
                selectNone={viewModel.surveyedSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updateVisitedFiltering}
                displayName="Site Inspected"
                options={viewModel.getBooleanOptionsForDropdown}
                value={() => viewModel.getVisitedFilter}
                showSelectAllNone={true}
                selectAll={viewModel.visitedSelectAll}
                selectNone={viewModel.visitedSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    return (
        <>
            {viewModel.showNoteModal && <TSMNotesModalView isOpen={viewModel.showNoteModal} closeModalCommand={viewModel.closeNoteModal} parentViewModel={viewModel} />}
            {viewModel.showDocumentModal && (
                <TSMDocumentsModalView isOpen={viewModel.showDocumentModal} closeModalCommand={viewModel.closeDocumentModal} parentViewModel={viewModel} />
            )}
            {viewModel.showRequestSiteVisitModal && (
                <TsmSiteVisitModal isOpen={viewModel.showRequestSiteVisitModal} closeModalCommand={viewModel.closeSiteVisitModal} parentViewModel={viewModel} />
            )}

            <TSMListWrapper>
                {isAdmin() && (
                    <Box maxWidth="200px" p="20px 0px">
                        <EditSelect
                            command={viewModel.chooseTsmToDisplay}
                            displayName="Select TSM:"
                            options={viewModel.getTSMsForAdminDropdown}
                            value={() => viewModel.getSelectedTsm}
                            isDisabled={viewModel.isProcessing}
                        />
                    </Box>
                )}
                <SharmansTable
                    columns={columns}
                    data={viewModel.model.tsmList.slice()}
                    filteringElementsTopSection={FilteringTopSection}
                    filteringElementsBottomSection={FilteringBottomSection}
                    onSortChange={updateSorting}
                    onChangeRowPerPage={viewModel.onChangeRowPerPage}
                    onChangePage={onChangePage}
                    showPagination={true}
                    totalRowCount={viewModel.totalCount}
                    totalPageCount={viewModel.pageCount}
                    onChangePageNumber={viewModel.changePageNumber}
                    pageSize={viewModel.pageSize}
                    pageNumber={viewModel.pageNumber}
                    onRowClick={onRowClick}
                    clearFilteringCommand={viewModel.clearFiltering}
                    isProcessing={viewModel.isProcessing}
                />
            </TSMListWrapper>
        </>
    );
});
