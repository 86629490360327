import { KeyValuePair, ModelBase } from "@shoothill/core";
import { action, makeObservable, observable } from "mobx";
import { Validator } from "Application/Validation";
import { AutocompleteAddress } from "getaddress-api";

export interface MatchingAddress {
    id: string;
    address: AutocompleteAddress;
}
export class AddressGenericModel extends ModelBase<AddressGenericModel> {
    public forceCounty: boolean = false;
    // public id: string = "";

    public postcode: string = "";
    public latitude: number = 0;
    public longitude: number = 0;

    public latitudeString: string = "";
    public longitudeString: string = "";

    public addresses = observable<MatchingAddress>([]);

    public selectedAddress: KeyValuePair = { key: "", text: "" };
    public addressLineOne: string = "";
    public addressLineTwo: string = "";
    public townCity: string = "";
    public county: string = "";
    public country: string = "United Kingdom";
    public countryId: number = -1;

    public validateFields: boolean = true;

    constructor() {
        super();
        makeObservable(this, {
            // id: observable

            postcode: observable,
            latitude: observable,
            longitude: observable,

            selectedAddress: observable,
            addressLineOne: observable,
            addressLineTwo: observable,
            townCity: observable,
            county: observable,
            country: observable,
            countryId: observable,

            validateFields: observable,

            setAddress: action,
            setForceCounty: action,
        });
    }

    public setForceCounty = (value: boolean | undefined) => {
        this.forceCounty = value === undefined ? false : value;
    };

    public clear = () => {
        //    this.id = "";
    };

    public setAddress = (address: AutocompleteAddress | undefined, countries: KeyValuePair<any>[]) => {
        // #13824 - sometimes there's no address line 2 returned,
        // but there is often a "locality".  See example in the task

        this.addressLineOne = address?.line_1!;
        this.addressLineTwo = address?.line_2!;
        this.townCity = address?.town_or_city!;
        if (address?.county === "") {
            this.county = address?.district!;
        } else {
            this.county = address?.county!;
        }

        this.country = "United Kingdom";
        if (address !== undefined) {
            this.country = address?.country!;

            const lower: string = address?.country!.toLowerCase();
            if (lower === "wales" || lower === "england" || lower === "scotland" || lower === "northern ireland") {
                this.country = "United Kingdom";
            }

            let countryId: KeyValuePair<any> | undefined = countries.find((a) => a.text === this.country);

            if (countryId !== undefined) {
                this.countryId = parseInt(countryId.key);
            } else {
                countryId = countries.find((a) => a.text === "Unknown Country");
                this.countryId = countryId === undefined ? -1 : parseInt(countryId.key);
            }
        }
    };
}

export class AddressGenericModelValidator extends Validator<AddressGenericModel> {
    constructor() {
        super();
        this.ruleFor("postcode").notEmpty().withMessage("Postcode must be between 5 & 8 characters").minLength(5).maxLength(8);

        this.ruleFor("postcode")
            .must((model) => model.length > 15)
            .withMessage("Latitude & Longitude is required")
            .when((model) => ((model.latitude == 0 && model.longitude == 0) || (model.latitude == undefined && model.longitude == undefined)) && model.validateFields === true);

        this.ruleFor("addressLineOne")
            .notEmpty()
            .withMessage("Please enter Address line 1")
            .notNull()
            .withMessage("Please enter Address line 1")
            .maxLength(256)
            .withMessage("Please enter an Address line 1 less than 256 characters.")
            .when((model) => model.validateFields === true);

        this.ruleFor("addressLineTwo")
            .maxLength(256)
            .withMessage("Please enter an Address line 2 less than 256 characters.")
            .when((model) => model.validateFields === true);

        this.ruleFor("townCity")
            .notEmpty()
            .withMessage("Please enter a Town/City")
            .notNull()
            .withMessage("Please enter a Town/City")
            .maxLength(256)
            .withMessage("Please enter a Town/City less than 256 characters.")
            .when((model) => model.validateFields === true);

        this.ruleFor("county")
            .maxLength(256)
            .withMessage("Please enter a County less than 256 characters.")
            .when((model) => model.validateFields === true);

        this.ruleFor("county")
            .notEmpty()
            .withMessage("Please enter a County")
            .notNull()
            .withMessage("Please enter a County")
            .when((model) => model.validateFields === true && model.forceCounty === true);

        this.ruleFor("countryId")
            .greaterThan(0)
            .withMessage("Please enter a Country")
            .notNull()
            .withMessage("Please enter a Country")
            .when((model) => model.validateFields === true);

        this.ruleFor("latitude")
            .inclusiveBetween(-90, 90)
            .withMessage("Please enter a valid Latitude -90 to 90")
            .when((model) => model.validateFields === true);

        this.ruleFor("longitude")
            .inclusiveBetween(-180, 180)
            .withMessage("Please enter a valid Longitude -180 to 180")
            .when((model) => model.validateFields === true);
    }
}

export interface IAddress {
    postcode: string;
    latitude: number;
    longitude: number;
    addressLineOne: string;
    addressLineTwo: string;
    townCity: string;
    county: string;
    country: string;
    countryId: number;
}
