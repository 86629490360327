import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Box } from "Application";
import { ButtonUnder } from "Components/Primitives/Buttons/ButtonUnder";
import ContactIcon from "Assets/Icons/ContactIcon.svg";
import { H5, P } from "Components/Primitives/TextElements/TextElements";
import { SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import { AddContactModal } from "../Modals/AddContactModalView";
import { CustomerContactsViewModel } from "./CustomerContactsViewModel";
import {
    BooleanTickOrCrossCell,
    CenteredTextCell,
    ContactNamePrimaryContactCell,
    EmailWithIconCell,
    HyphenIfNoValueCell,
    PhoneNumberWithIconCell,
} from "Utils/CellFormatComponents";
import { formatDate } from "Utils/Formats";
import { NewCustomerViewModel } from "../NewCustomerViewModel";
import { EditButton } from "Components/Primitives/Buttons/EditButton";
import { DeleteButton } from "Components/Primitives/Buttons/DeleteButton";
import { AreYouSureModal } from "Components/AreYouSureModal/AreYouSure";
import { AddSectionsBox, ButtonBox } from "Components/CommonPageComponents";
import { ContactStatusTypeEnum } from "Models/Contacts/ContactStatusTypeEnun";
import { useParams } from "react-router";
import { ViewContactModal } from "Views/Directory/Contacts/Modals/ViewContactModal";

interface IContacts {
    customerId: Guid;
    parentViewModel: NewCustomerViewModel;
    contactCountCallBack: (newCount: number) => void;
}

export const CustomerContactsView: React.FC<IContacts> = observer((props) => {
    const [viewModel] = useState(() => new CustomerContactsViewModel(props.contactCountCallBack, props.customerId));
    const params = useParams();

    useEffect(() => {
        if (params.customerId) {
            viewModel.setCustomerIdFromParent(params.customerId);
            viewModel.SortContacts();
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, []);

    /*     useEffect(() => {
        if (props.parentViewModel.contacts) {
            viewModel.updateContactsForEditing(props.parentViewModel.contacts);
        }
        return () => {
            // Clean up after yourself
            //viewModel.clear();
        };
    }, [props.parentViewModel.contacts]); */

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.setValue("columnName", columnName);
        viewModel.setValue("orderBy", orderBy);
        viewModel.SortContacts();
    };

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("name", {
            header: () => "Name",
            cell: (info) => <ContactNamePrimaryContactCell name={info.row.original.name} primaryContact={info.row.original.primaryContact} />,
        }),

        columnHelper.accessor("jobTitle", {
            header: () => "Job title",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("phone", {
            header: () => "Contact phone no.",
            cell: (info) => <PhoneNumberWithIconCell value={info.getValue()} />,
        }),
        columnHelper.accessor("email", {
            header: () => "Contact email address",
            cell: (info) => <EmailWithIconCell value={info.getValue()} />,
        }),

        columnHelper.accessor("datePlygeneTrained", {
            header: () => "Plygene trained",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.PlygeneTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("dateCoatingsTrained", {
            header: () => "Coatings trained",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.CoatingsTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("datePlygeneCPD", {
            header: () => "Plygene CPD",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.PlygeneTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("dateCoatingsCPD", {
            header: () => "Coatings CPD",
            cell: (info) =>
                (info.row.original.status === ContactStatusTypeEnum.CoatingsTrained && info.renderValue() === null) ||
                (info.row.original.status === ContactStatusTypeEnum.TrainedBoth && info.renderValue() === null) ? (
                    <BooleanTickOrCrossCell value={true} />
                ) : (
                    <CenteredTextCell greenText={true} value={info.renderValue() !== null ? formatDate(new Date(info.renderValue())) : null} />
                ),
        }),

        columnHelper.accessor("createdDate", {
            header: () => "Date added",
            cell: (info) => <CenteredTextCell value={info.getValue() !== null ? formatDate(new Date(info.getValue())) : ""} />,
        }),
        // columnHelper.accessor("status", {
        //     header: () => "Status",
        //     cell: (info) => (
        //         <ContactStatusTableBox color={ContactStatusTypeHelper.GetColor(info.getValue())}>{ContactStatusTypeHelper.GetText(info.getValue())}</ContactStatusTableBox>
        //     ),
        // }),
        columnHelper.accessor("id", {
            header: () => "Actions",
            cell: (info) => (
                <Box className="action-cell">
                    <EditButton id={info.getValue().toString()} onClick={viewModel.openEditContactModal} />
                    <DeleteButton id={info.getValue().toString()} onClick={viewModel.deleteContactCheck} />
                </Box>
            ),
        }),
    ];

    function onRowClick(rowId: string | number): void {
        viewModel.openViewContactModal(String(rowId));
    }

    return (
        <>
            <AddContactModal
                addOrEdit={viewModel.modalType}
                isOpen={viewModel.showAddContactModal}
                closeModal={viewModel.closeAddNewContactModal}
                viewModel={viewModel}
                parentViewModel={props.parentViewModel}
            />

            <AreYouSureModal
                title="Delete Contact"
                message="Are you sure you want to delete this contact?"
                isOpen={viewModel.areYouSureModelOpen}
                onCancel={viewModel.closeAreYouSureModal}
                onConfirm={viewModel.confirmDeleteContact}
            />

            <AddSectionsBox>
                <ButtonBox>
                    <ButtonUnder
                        displayName=" Add contact"
                        paletteColor={viewModel.isProcessing === true ? "ButtonInactive" : "ButtonBlue"}
                        icon={<img src={ContactIcon} />}
                        boxShadow={true}
                        command={viewModel.openAddNewContactModal}
                    />
                </ButtonBox>
                <H5 p="20px 0px">Contacts</H5>
            </AddSectionsBox>

            {viewModel.showViewContactModal && (
                <ViewContactModal isOpen={viewModel.showViewContactModal} contactId={viewModel.contactIdToView} closeModal={viewModel.closeViewContactModal} />
            )}

            <SharmansTable columns={columns} data={viewModel.getcontacts} onSortChange={updateSorting} onRowClick={onRowClick} />
            {viewModel.contacts.length === 0 && (
                <Box flexBox justifyContent="center">
                    <P>There are currently no contacts added to this customer.</P>
                </Box>
            )}
        </>
    );
});
