import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ICommand, RelayCommand } from "Application";
import { PaginationDirection, SharmansTable, SortOrderDirection } from "Components/Primitives/DataTable/SharmansTable";
import { createColumnHelper } from "@tanstack/react-table";
import { H2 } from "Components/Primitives/TextElements/TextElements";
import { CustomersListWrapper } from "./CustomersList.styles";
import { SubHeaderView, subHeadingButton } from "Views/Header/SubHeader";
import { CustomersListViewModel } from "./CustomersListViewModel";
import { FilteringTopSectionGenericView } from "Components/FilteringTopSectionGeneric";
import { AppUrls } from "AppUrls";
import { useNavigate } from "react-router";
import { CenteredCell, CustomerStatusButtonCell, HyphenIfNoValueCell } from "Utils/CellFormatComponents";
import { formatDate, hyphenIfNoValue } from "Utils/Formats";
import { CustomerStatusHelpers } from "Application/Models/Domain/Customer/CustomerStatusEnum";
import { MultiEditSelect } from "Components/Primitives/Selects/MultiEditSelect";
import { KeyValuePair } from "@shoothill/core";
import { FilteringBottomSectionBox } from "Components/FilteringBottomSectionBox";

export const CustomersListView: React.FC = observer(() => {
    const [viewModel] = useState(() => CustomersListViewModel.Instance);
    let debounceTimer: NodeJS.Timeout | null = null;
    const navigate = useNavigate();
    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        viewModel.loadCustomersAsync();

        return () => {
            // Clean up after yourself
            // Don't clear, we want to keep this since using a singleton viewModel.clear();
            // viewModel.reset();
        };
    }, []);

    useEffect(() => {
        if (isFiltering) {
            debounceFilter();
        }
    }, [isFiltering, viewModel]);

    const columnHelper = createColumnHelper<any>();

    const columns = [
        columnHelper.accessor("name", {
            size: 500,
            header: () => "Customer name",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("type", {
            size: 80,
            header: () => "Type",
            cell: (info) => info.getValue(),
        }),
        columnHelper.accessor("sageRef", {
            header: () => "Sage ref",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("leadTsmName", {
            header: () => "Lead TSM",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("bdmName", {
            header: () => "BDM",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("phone", {
            size: 80,
            header: () => "Contact number",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("multiSiteProjectCount", {
            size: 60,
            header: () => "Multi",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("masterProjectCount", {
            size: 60,
            header: () => "Master",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("projectQuoteCount", {
            size: 60,
            header: () => "Quote",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("townCity", {
            header: () => "Town/City",
            cell: (info) => <HyphenIfNoValueCell value={info.renderValue()} />,
        }),
        columnHelper.accessor("postCode", {
            size: 60,
            header: () => "Postcode",
            cell: (info) => <CenteredCell>{hyphenIfNoValue(info.renderValue())}</CenteredCell>,
        }),
        columnHelper.accessor("createdDate", {
            header: () => "Date created",
            cell: (info) => <CenteredCell>{formatDate(new Date(info.renderValue()))}</CenteredCell>,
        }),
        columnHelper.accessor("customerStatusId", {
            header: () => "Status",
            cell: (info) => <CustomerStatusButtonCell status={info.renderValue()} command={viewModel.tempCommand} />,
        }),
    ];

    const onChangePage = (change: PaginationDirection) => {
        switch (change) {
            case PaginationDirection.NEXT:
                viewModel.pageNumber < viewModel.pageCount ? viewModel.pageNumber++ : null;
                viewModel.loadCustomersAsync();
                break;
            case PaginationDirection.BACK:
                viewModel.pageNumber > 1 ? viewModel.pageNumber-- : null;
                viewModel.loadCustomersAsync();

                break;
            case PaginationDirection.START:
                viewModel.pageNumber = 1;
                viewModel.loadCustomersAsync();

                break;
            case PaginationDirection.END:
                viewModel.pageNumber = viewModel.pageCount;
                viewModel.loadCustomersAsync();

                break;
            default:
            // code block
        }

        // }
    };

    const onChangeRowPerPage = (rowsPerPage: number) => {
        viewModel.pageSize = rowsPerPage;
        viewModel.pageNumber = 1;

        viewModel.loadCustomersAsync();
    };

    const updateSorting = (columnName: string, orderBy: SortOrderDirection) => {
        viewModel.columnName = columnName;
        viewModel.orderBy = orderBy;
        viewModel.loadCustomersAsync();
    };

    const handleSubHeadingButtonClicks: ICommand = new RelayCommand((value: subHeadingButton) => {
        switch (value) {
            case subHeadingButton.New:
                navigate(AppUrls.Client.Directory.CustomersNew);
                break;
            case subHeadingButton.Download:
                viewModel.DownloadCSVAsync();
                break;

            default:
            // code block
        }
    });

    function onRowClick(Id: string | number): void {
        const path: string = `/directory/customers/view/${Id.toString()}/details`;
        navigate(path);
    }

    const FilteringTopSection = (
        <FilteringTopSectionGenericView
            searchValue={viewModel.searchString}
            searchCommand={viewModel.updateKeywordSearch}
            timescaleOptions={[]}
            timescaleValue={""}
            displayName="Start typing to search by name, sage ref, phone, postcode, email etc... "
            isDisabled={!viewModel.getCanExecute}
            showOnlySearchPostcode={true}
            isPostCodeSearchOnly={viewModel.getIsSearchPostCodeOnly}
            onPostCodeOnlySearchChange={viewModel.onPostCodeOnlySearchChange}
        />
    );

    const debounceFilter = () => {
        if (debounceTimer) {
            clearTimeout(debounceTimer);
        }
        debounceTimer = setTimeout(() => {
            viewModel.loadCustomersAsync();
            setIsFiltering(false);
        }, 1200);
    };

    const updateBdmFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateBdmFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const updateTSMFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateTsmFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const updateTypeFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateTypeFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const updateFilterByStatusFiltering: ICommand = new RelayCommand((values: KeyValuePair[]) => {
        viewModel.updateFilterByStatusFiltering(
            values.map((item) => {
                return item.key;
            }),
        );
        viewModel.pageNumber = 1;
        debounceFilter();
    });

    const bdmSelectAll = (): void => {
        viewModel.bdmSelectAll();
        viewModel.loadCustomersAsync();
    };

    const bdmSelectNone = (): void => {
        viewModel.bdmSelectNone();
        viewModel.loadCustomersAsync();
    };

    const tsmSelectAll = (): void => {
        viewModel.tsmSelectAll();
        viewModel.loadCustomersAsync();
    };

    const tsmSelectNone = (): void => {
        viewModel.tsmSelectNone();
        viewModel.loadCustomersAsync();
    };

    const typeSelectAll = (): void => {
        viewModel.typeSelectAll();
        viewModel.loadCustomersAsync();
    };

    const typeSelectNone = (): void => {
        viewModel.typeSelectNone();
        viewModel.loadCustomersAsync();
    };

    const filterByStatusSelectAll = (): void => {
        viewModel.filterByStatusSelectAll();
        viewModel.loadCustomersAsync();
    };

    const filterByStatusSelectNone = (): void => {
        viewModel.filterByStatusSelectNone();
        viewModel.loadCustomersAsync();
    };

    const FilteringBottomSection = (
        <FilteringBottomSectionBox grid dc={"1fr 1fr 1fr 1fr 1fr"}>
            <MultiEditSelect
                command={updateTypeFiltering}
                displayName="Type"
                options={viewModel.getCustomerTypesForDropdown}
                value={() => viewModel.getTypeFilter}
                showSelectAllNone={true}
                selectAll={typeSelectAll}
                selectNone={typeSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updateBdmFiltering}
                displayName="Filter by BDM"
                options={viewModel.getBdms}
                value={() => viewModel.getBdmFilter}
                showSelectAllNone={true}
                selectAll={bdmSelectAll}
                selectNone={bdmSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updateTSMFiltering}
                displayName="Filter by lead TSM"
                options={viewModel.getTsms}
                value={() => viewModel.getTsmFilter}
                showSelectAllNone={true}
                selectAll={tsmSelectAll}
                selectNone={tsmSelectNone}
                isDisabled={viewModel.isProcessing}
            />
            <MultiEditSelect
                command={updateFilterByStatusFiltering}
                displayName="Filter by status"
                options={CustomerStatusHelpers.getStatusForDropdown()}
                value={() => viewModel.getFilterByStatusFilter}
                showSelectAllNone={true}
                selectAll={filterByStatusSelectAll}
                selectNone={filterByStatusSelectNone}
                isDisabled={viewModel.isProcessing}
            />
        </FilteringBottomSectionBox>
    );

    return (
        <>
            <SubHeaderView buttonClick={handleSubHeadingButtonClicks} newButtonText="New customer" downloadButtonText="Download as csv" />

            <CustomersListWrapper>
                <H2 pb="10px">Customers</H2>
                <SharmansTable
                    columns={columns}
                    onSortChange={updateSorting}
                    data={viewModel.getCustomers}
                    onRowClick={onRowClick}
                    showPagination={true}
                    filteringElementsBottomSection={FilteringBottomSection}
                    filteringElementsTopSection={FilteringTopSection}
                    totalRowCount={viewModel.customerCount}
                    onChangePage={onChangePage}
                    onChangePageNumber={viewModel.changePageNumber}
                    onChangeRowPerPage={onChangeRowPerPage}
                    totalPageCount={viewModel.pageCount}
                    pageNumber={viewModel.pageNumber}
                    pageSize={viewModel.pageSize}
                    isProcessing={viewModel.isProcessing}
                    clearFilteringCommand={viewModel.clearFiltering}
                />
            </CustomersListWrapper>
        </>
    );
});
